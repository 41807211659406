@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

.share-box a {
  display: inline-block;
  -webkit-box-shadow: 0 0 1px #777;
  box-shadow: 0 0 1px #777;
  padding: 5px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-decoration: none; }
  .share-box a:hover {
    text-decoration: none;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear; }

.f {
  color: #3b5998; }
  .f:hover {
    color: #fff;
    background-color: #3b5998; }

.t {
  color: #4099FF; }
  .t:hover {
    color: #fff;
    background-color: #4099FF; }

.g {
  color: #d34836; }
  .g:hover {
    color: #fff;
    background-color: #d34836; }

.r {
  color: #ff5700; }
  .r:hover {
    color: #fff;
    background-color: #ff5700; }

.l {
  color: #0077b5; }
  .l:hover {
    color: #fff;
    background-color: #0077b5; }

.e {
  color: #444444; }
  .e:hover {
    color: #fff;
    background-color: #444444; }

// Links
a {
  color: blue;
  font-family: helvetica;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: red;
}

a:visited {
  color: purple;
}